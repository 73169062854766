import * as React from "react";

import * as d3 from "d3";
import { useParams } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getFloor, getSvg } from "../../redux/actions/floorActions";
import { handleErrors } from "utils/helpers";

export const Floor: React.FC = () => {
  const { floorId, officeId, workstationId } = useParams();
  const dispatch = useAppDispatch();
  const { floor } = useAppSelector((state) => state.floor);

  React.useEffect(() => {
    dispatch(getFloor(Number(floorId)))
      .unwrap()
      .then((res) => {
        const mapId = res.floorMapId;

        mapId &&
          dispatch(getSvg(mapId))
            .unwrap()
            .then((res) => {
              var reader = new FileReader();
              reader.readAsDataURL(new File([res], ""));
              reader.onload = function (event) {
                // @ts-ignore
                const fileData: string = event?.target?.result;

                d3.xml(fileData).then((data) => {
                  // @ts-ignore
                  d3.select(`#svg_container`).html("");
                  // @ts-ignore
                  d3.select(`#svg_container`)
                    .node()
                    // @ts-ignore
                    ?.append(data.documentElement);

                  const sizes = d3.select("#Layer_1").attr("viewBox").split(" ");

                  const width = sizes[2],
                    height = sizes[3];
                  const zoom = d3
                    .zoom()
                    .scaleExtent([0.5, 3])
                    .translateExtent([
                      [0, 0],
                      [Number(width), Number(height)],
                    ])
                    .on("zoom", (e) => {
                      d3.select("svg g").attr("transform", e.transform);
                    });
                  // @ts-ignore
                  d3.select("#Layer_1").call(zoom).on("dblclick.zoom", null);

                  if (workstationId !== undefined && officeId !== undefined) {
                    const table = d3.select(`#path_${workstationId}_office_${officeId}`);
                    const user = d3.select(`#user_${workstationId}_office_${officeId}`);
                    const company = d3.select(`#company_${workstationId}_office_${officeId}`);

                    if (!table.empty()) {
                      table.style("fill", "var(--orange)");
                    }
                    if (!user.empty()) {
                      user.style("fill", "black");
                    }
                    if (!company.empty()) {
                      company.style("fill", "black");
                    }
                  }
                });
              };
            })
            .catch((res) => {
              handleErrors(res);
            });
      });
  }, [dispatch, floorId, officeId, workstationId]);

  return (
    <div className="floor">
      <h1 className="floor__title">
        <span className="floor__title-left">{floor?.nameForUsers}</span>
        <a
          className="floor__link"
          href={`https://www.google.com/maps?q=${floor?.building?.latitude},${floor?.building?.longitude}`}
          target="_blank"
          rel="noreferrer">
          <span className="floor__title-right">
            {floor?.building?.address}, {floor?.building?.city?.name}, {floor?.building?.city?.country?.name}
          </span>
        </a>
      </h1>
      <div className="floor__plan" id="svg_container"></div>
    </div>
  );
};
