import { createAsyncThunk } from "@reduxjs/toolkit";

import { Api } from "../../api/index";
import { FloorWithBuildingInfoResponse } from "../../api/generated/index";

export const getFloors = createAsyncThunk("getFloors", async (): Promise<FloorWithBuildingInfoResponse[]> => {
  return Api.Floor.getAllFloors().then(({ data }) => {
    return data;
  });
});

export const getFloor = createAsyncThunk("getFloor", async (id: number): Promise<FloorWithBuildingInfoResponse> => {
  return Api.Floor.getFloorById(id).then(({ data }) => {
    return data;
  });
});

export const getSvg = createAsyncThunk("getSvg", async (name: number) => {
  return Api.Floor.getSVGById(name).then(({ data }) => {
    return data;
  });
});
