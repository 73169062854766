import * as React from "react";

import { useParams, useNavigate } from "react-router-dom";
import * as d3 from "d3";

import { getBuilding } from "../../redux/actions/buildingActions";
import { getSvg } from "../../redux/actions/floorActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FloorResponse } from "../../api/generated";
import { AppRoutes } from "../../config";
import { handleErrors } from "../../utils/helpers";

export const Building: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { building } = useAppSelector((state) => state.building);
  const { buildingId } = useParams();

  const handleNavigate = (floorId: number) => {
    navigate(AppRoutes.createRouteForFloor(floorId));
  };

  const handleFloors = () => {
    return building?.floorList?.map((item) => {
      return (
        <div key={item.id} className="building__floor" onClick={() => handleNavigate(item.id!)}>
          <h3 className="building__floor-title">{item.nameForUsers}</h3>
          <div id={`container_${item.id}`} className="building__floor-content"></div>
        </div>
      );
    });
  };

  const displaySvgFiles = React.useCallback(
    (floors: FloorResponse[]) => {
      for (let item of floors!) {
        const mapId = item.floorMapId;

        mapId &&
          dispatch(getSvg(mapId))
            .unwrap()
            .then((res) => {
              var reader = new FileReader();
              reader.readAsDataURL(new File([res], ""));
              reader.onload = function (event) {
                // @ts-ignore
                const fileData: string = event?.target?.result;

                d3.xml(fileData).then((data) => {
                  // @ts-ignore
                  d3.select(`#container_${item.id}`).html("");
                  // @ts-ignore
                  d3.select(`#container_${item.id}`)
                    .node()
                    // @ts-ignore
                    ?.append(data.documentElement);
                  d3.selectAll("#tables g").each(function () {
                    d3.select(this).style("visibility", "hidden");
                  });
                  d3.selectAll("text").each(function () {
                    d3.select(this).style("visibility", "hidden");
                  });
                });
              };
            })
            .catch((res) => {
              handleErrors(res);
            });
      }
    },
    [dispatch],
  );

  React.useEffect(() => {
    dispatch(getBuilding(Number(buildingId)))
      .unwrap()
      .then((res) => {
        displaySvgFiles(res.floorList!);
      })
      .catch((res) => {
        handleErrors(res);
      });
  }, [dispatch, buildingId, displaySvgFiles]);

  return (
    <div className="building">
      <div className="building__holder">
        <h1 className="building__title">
          <span>Building</span>
          <a
            className="building__location"
            href={`https://www.google.com/maps?q=${building?.latitude},${building?.longitude}`}
            target="_blank"
            rel="noreferrer">
            {" "}
            {building?.name}, {building?.city?.name}, {building?.city?.country?.name}
          </a>
        </h1>
      </div>
      <div className="building__floors-wrap">{handleFloors()}</div>
    </div>
  );
};
